.App {
  text-align: center;
}

main {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
