@import '../../utils/colors.css';

.infoContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.summaryTableDiv {
    border: solid 1px var(--background);
    border-radius: 5px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    margin-left: 1rem;
    height: 22rem;
    width: 90%;
}

.newAuditReqDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.newAuditBtn {
    width: 20%;
    height: 2.5rem;
    font-size: 1.3rem;
    background-color: var(--HOTHred);
    border: var(--HOTHred) solid 1px;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
}

.newAuditBtn:hover {
    background-color: var(--darkRed);
    border: var(--darkRed) solid 1px;
}

.groupInfoDiv {
    width: 100%;
    padding: 0;
    margin: 0;
}

.groupInfo {
    color: var(--HOTHred);
    font-weight: bolder;
}