@import '../../utils/colors.css';

.resultsContainer {
    border: solid 1px var(--background);
    border-radius: 5px;
    background-color: var(--background);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
    margin: 1.5rem;
    width: 92%;
}

.targetDomainDisplay {
    text-align: center;
}

.tableHeader {
    border-radius: 5px 5px 0 0;
}

.actionBtnsDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.downloadBtn, .refreshBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
    height: 2.5rem;
    font-size: 1.8rem;
    background-color: var(--HOTHred);
    border: var(--HOTHred) solid 1px;
    color: var(--white);
    border-radius: 5px;
    cursor: pointer;
}

.CSVLink {
    color: var(--white)
}

.downloadBtn:hover, .refreshBtn:hover {
    background-color: var(--darkRed);
    border: var(--darkRed) solid 1px;
}